jQuery(document).ready(function($) {
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		offCanvas: {
			position: "top",
			zposition: "front"
		},
		slidingSubmenus: false
	}).on( "closed.mm", function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	$mobileMenuTrigger.click(function() {
		$mobileMenu.trigger("open.mm");

		$mobileMenuTrigger.toggleClass("w--open");
	});

	/* webflow */
	/* eslint-disable */
	Webflow.require('ix').init([
		{"slug":"homepage-slide-up","name":"Homepage Slide Up","value":{"style":{"opacity":0,"x":"0px","y":"30px"},"triggers":[{"type":"load","stepsA":[{"opacity":1,"wait":500,"transition":"transform 500ms ease 0ms, opacity 500ms ease 0ms","x":"0px","y":"0px"}],"stepsB":[]}]}}
	]);
	/* eslint-enable */
});
